import _ from "lodash";
import { IExchangeConfig } from "apis/exchange/interface";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1min',
    '5': '5min',
    '15': '15min',
    '30': '30min',
    '60': '1h',
    '240': '4h',
    '360': '6Hutc',
    '720': '12Hutc',
    'D': '1Dutc',
    '1D': '1Dutc',
    '3D': '3Dutc',
    'W': '1Wutc',
    '1W': '1Wutc',
    'M': '1Mutc',
    '1M': '1Mutc'
}

const BitgetConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/bitget",

    wsEndpoint: "wss://ws.bitget.com/spot/v1/stream",

    defaultTicker: "BTC/USDT",

    spotTickersRestPath: "/api/spot/v1/public/products",

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Symbols] Bitget`, rawSymbolsData)
        const symbolRet = rawSymbolsData?.data;
        const code = rawSymbolsData?.code;
        if (_.isNil(symbolRet)) {
            throw { msg: "Bitget Parse Symbols Error" }
        }
        if (code !== "00000") {
            throw { msg: "Bitget Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        symbolRet.forEach((elm) => { // krw market
            const { baseCoin, quoteCoin, symbol } = elm;
            const ticker = `${baseCoin}/${quoteCoin}`;
            const exchangeSymbol = symbol;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        console.log(`[Parse Symbols] Bitget`, _.size(symbolInfoObj))
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] Bitget`, symbol, resolution)
        return `/api/spot/v1/market/candles`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] Bitget`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {
            symbol,
            period: resolutionMap[resolution],
            before: requestToMsec,
            after: requestFromMsec,
            limit: limit,
        }
    },

    parseHistoricalBar: function (rawBarData: any) {
        // console.log(`[Parse Historical Bar] Bitget`, rawBarData)
        let bars = [];
        const barRet = rawBarData?.data;
        const code = rawBarData?.code;
        if (_.isNil(barRet)) {
            throw { msg: "Bitget Parse Bars Error" }
        }
        if (code !== "00000") {
            throw { msg: "Bitget Parse Bars Error" }
        }
        barRet.forEach((elm) => {
            bars = [...bars, {
                time: Number(elm.ts),
                open: Number(elm.open),
                high: Number(elm.high),
                low: Number(elm.low),
                close: Number(elm.close),
                volume: Number(elm.baseVol) // usdtVol
            }];
        })
        return bars.sort((a, b) => a.time - b.time);
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] Bitget`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("[Subscribe Message] Bitget", symbol)
        return {
            op: "subscribe",
            args: [
                {
                    "instType": "sp",
                    "channel": "trade",
                    "instId": symbol.split("_")[0]
                }
            ]
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("[Unsubscribe Messsage] Bitget", symbol)
        return {
            op: "unsubscribe",
            args: [
                {
                    "instType": "sp",
                    "channel": "trade",
                    "instId": symbol.split("_")[0]
                }
            ]
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] Bitget`, rawMsgObj)
        const tradeRet = rawMsgObj?.data;
        const channel = rawMsgObj?.arg?.channel;
        const action = rawMsgObj?.action;
        if (_.isNil(tradeRet)) {
            console.log("[Parse Trade Tick] Bitget Other Message 1", rawMsgObj)
            return { status: "other" };
        }
        if (channel !== "trade") {
            console.log("[Parse Trade Tick] Bitget Other Message 2", rawMsgObj)
            return { status: "other" };
        }
        if(action !== "update") {
            console.log("[Parse Trade Tick] Bitget Other Message 3", rawMsgObj)
            return { status: "other" };
        }
        let tradeArr = [];
        tradeRet.forEach((elm) => {
            const tradeTime = Number(elm[0]); //msec
            const tradePrice = Number(elm[1]);
            const tradeSize = Number(elm[2]);
            const newTrade = {
                tradeTime,
                tradePrice,
                tradeSize,
            }
            tradeArr.push(newTrade)
        })
        // console.log(`[Parsed Trade Tick] Bitget`, tradeArr)
        return { tradeArr, status: "array" };
    }
}

export default BitgetConfig;