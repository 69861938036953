export default {
    interval: '1',
    library_path: '/charting_library/',
    enabled_features: [],
    disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', "left_toolbar", "header_undo_redo", "header_templates"],
    clientId: 'defimix-client',
    userId: 'defimix-user',
    custom_formatters: {
        dateFormatter: {
            format: (date) => {
                return date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate();
            }
        }
    },
    timezone: "Asia/Seoul",
    fullscreen: false,
    autosize: true,
};