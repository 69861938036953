import React from "react"
import SideNav from "components/SideNav"

const menus = [
    {
        title: "Main",
        subMenus: [
            {
                title: "Home",
                to: "/main/home",
            },
        ],
    },
    {
        title: "Trading",
        subMenus: [
            {
                title: "Chart",
                to: "/trading/chart",
            },
        ],
    },
]

export default () => {
    return (
        <SideNav menus={menus}></SideNav>
    )
}