import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './styles.scss';
import { EXAMPLE_ASKS, EXAMPLE_BIDS } from './example';

export const formatNumber = (x, round = 10, nanValue = "") => {
    try {
        if (_.isNil(x) || _.isNaN(x)) {
            return nanValue;
        }
        if (typeof x === 'string') {
            x = Number(Number(x).toFixed(round))
        }
        if (typeof x === 'boolean') {
            return String(x)
        }
        var parts = x.toFixed(round).split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
    }
    catch (e) {
        console.log(e);
        return JSON.stringify(x);
    }
}

const getMarkStyle = (orders, price) => {
    return (_.isEmpty(orders.filter((order) => Number(order.price) === Number(price))) ? {} : {
        fontWeight: 'bold'
    })
}

const Depth = ({ askArr = EXAMPLE_ASKS, bidArr = EXAMPLE_BIDS, orders = [], limit = 10 }) => {
    let liitedAskArr = askArr.sort((elmA, elmB) => elmA[0] - elmB[0]).filter((elm, i) => i < limit)
    let limitedBidArr = bidArr.sort((elmA, elmB) => elmB[0] - elmA[0]).filter((elm, i) => i < limit)

    const askTotal = liitedAskArr.reduce((total, elm) => {
        return total + elm[1];
    }, 0)

    const bidTotal = limitedBidArr.reduce((total, elm) => {
        return total + elm[1];
    }, 0)

    useEffect(() => {
        // console.log("[Depth Table] Use Effect orders", orders)
    }, [orders])

    const isOrderExist = !_.isEmpty(orders);

    return (
        <div>
            <div className="flex">
                <div className="w-full">
                    <table className="border-separate border-spacing-[1px] shadow min-w-full bg-gray-300">
                        <thead>
                            <tr>
                                <th className="text-center bg-white">Bid Size</th>
                                <th className="text-center bg-white">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {limitedBidArr.map((bid) => (
                                <tr>
                                    <td className="bidSize text-start py-1 bg-white" style={{ backgroundSize: `${bid[1] / bidTotal * 100}% 100%`, ...getMarkStyle(orders, bid[0]) }}> {formatNumber(bid[1], 4)}</td >
                                    <td className="bidPrice text-center py-1 bg-white" style={{ ...getMarkStyle(orders, bid[0]) }}>{formatNumber(bid[0], 2)}</td>
                                </tr >
                            ))}
                        </tbody >
                    </table >
                </div >
                <div className="w-full">
                    <table className="border-separate border-spacing-[1px] shadow min-w-full bg-gray-300">
                        <thead>
                            <tr>
                                <th className="text-center bg-white">Price</th>
                                <th className="text-center bg-white">Ask Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {liitedAskArr.map((ask) => (
                                <tr>
                                    <td className="askPrice text-center py-1 bg-white" style={{ ...getMarkStyle(orders, ask[0]) }}>{formatNumber(ask[0], 2)}</td>
                                    <td className="askSize text-end py-1 bg-white" style={{ backgroundSize: `${ask[1] / askTotal * 100}% 100%`, ...getMarkStyle(orders, ask[0]) }}>{formatNumber(ask[1], 4)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Depth;