import exchangeConfig from './config';

export default class ExchangeWsClient {
    wsClient = null;
    onMsgReceived = (obj) => { }
    constructor(onMsgReceived = (obj) => { }) {
        this.onMsgReceived = onMsgReceived
    }

    startWsClient = (exchange, symbol, channel = "depth") => { // depth || trade
        const wsClient = new WebSocket(exchangeConfig[exchange].wsEndpoint);

        wsClient.onopen = () => {
            const connectedMsg = `[Exchange Public Ws] Connected`;
            console.log(connectedMsg);
            let subscribeMsg = {};
            if (channel === "depth") {
                subscribeMsg = exchangeConfig[exchange].getDepthSubscribeMsg(symbol);
            } else if (channel === "trade") {
                subscribeMsg = exchangeConfig[exchange].getTradeSubscribeMsg(symbol);
            }
            wsClient.send(JSON.stringify(subscribeMsg));
        }

        wsClient.onclose = () => {
            const closeMsg = `[Exchange Public Ws] Closed`;
            console.log(closeMsg);
            // onClosed();
        }

        wsClient.onmessage = (event) => {
            this.onMsgReceived(JSON.parse(event.data));
        }

        this.wsClient = wsClient;
    }

    closeWsClient = () => {
        this.wsClient.close()
    }

}