import { IExchangeConfig } from "apis/exchange/interface";
import _ from "lodash";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1',
    '3': '3',
    '5': '5',
    '10': '10',
    '15': '15',
    '30': '30',
    '60': '60',
    '1D': '24h',
    '1W': '1w',
    '1M': '1m'
}

const GopaxConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/gopax",

    wsEndpoint: "wss://wsapi.gopax.co.kr",

    defaultTicker: "BTC/KRW",

    spotTickersRestPath: "/trading-pairs",

    parseTickers: function (rawSymbolsData: any) {
        console.log(`[Parse Tickers] Gopax`, rawSymbolsData)
        const dataArr = rawSymbolsData;
        if (_.isNil(dataArr)) {
            throw { msg: "Gopax Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        dataArr?.forEach((elm) => { // krw market
            const { baseAsset, quoteAsset, name } = elm;
            const ticker = `${baseAsset}/${quoteAsset}`;
            const exchangeSymbol = name;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] Gopax`, symbol, resolution)
        return `/trading-pairs/${symbol}/candles`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] Gopax`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        const params = {
            start: requestFromMsec,
            end: requestToMsec,
            interval: resolutionMap[resolution],
            limit: limit,
        }
        return params;
    },

    parseHistoricalBar: function (rawBarData: any) {
        console.log(`[Parse Historical Bar] Gopax`, rawBarData)
        const dataArr = rawBarData;
        if (_.isNil(dataArr)) {
            throw { msg: "Gopax Parse Bars Error" }
        }
        let bars = [];
        dataArr.forEach((elm) => {
            bars = [...bars, {
                time: Number(elm[0]),
                open: Number(elm[3]),
                high: Number(elm[2]),
                low: Number(elm[1]),
                close: Number(elm[4]),
                // volume: Number(elm.v) * Number(elm.o)
                volume: Number(elm[5])
            }];
        })
        return bars.sort((a, b) => a.time - b.time);
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] Gopax`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("SUBSCRIBE", symbol) // Example: C0101_C0100
        const msg = {
            "n": "SubscribeToTradingPair",
            "o": {
                "tradingPairName": symbol
            }
        }
        return msg
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("UNSUBSCRIBE", symbol) // Example: C0101_C0100
        const [quoteAsset, baseAsset] = symbol.split("/");
        const msg = {
            "n": "UnSubscribeToTradingPair",
            "o": {
                "tradingPairName": symbol
            }
        }
        return msg;
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] Gopax`, rawMsgObj)
        if (rawMsgObj.n !== "PublicTradeEvent") {
            console.log("[Parse Trade Tick] Gopax Other Message", rawMsgObj)
            return { status: "other" };
        }
        const tradeTime = Number(rawMsgObj?.o?.occurredAt * 1000);
        const tradePrice = Number(rawMsgObj?.o?.price);
        const tradeSize = Number(rawMsgObj?.o?.baseAmount);
        return { tradeTime, tradePrice, tradeSize, status: "diff" }
    }
}

export default GopaxConfig;