import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/Card';
import PageTitle from 'components/Title/Page';
import TvChart from 'components/Chart/TvChart';
import Depth from 'components/Financial/Depth';
import ExchangeWsClient from 'apis/exchange/ws';
import { widget as TvWidget, IChartWidgetApi, IChartingLibraryWidget } from 'libs/charting_library';
import CustomDatafeed from './datafeed';
import CustomStreaming from './streaming';
import exchangeConfig from 'apis/exchange/config'
import ExchangeApi from 'apis/exchange';
import Button from 'components/Form/Button';


interface OrderBook {
    bids: Order[];
    asks: Order[];
}

type Order = [number, number]; // [price, size]

function processSnapshot(snapshot: any): OrderBook {
    const { b, a } = snapshot.data;

    const bids = b.map(([price, size]: [string, string]) => [parseFloat(price), parseFloat(size)]);
    const asks = a.map(([price, size]: [string, string]) => [parseFloat(price), parseFloat(size)]);

    return {
        bids: bids.sort((a, b) => b[0] - a[0]), // Descending for bids
        asks: asks.sort((a, b) => a[0] - b[0]), // Ascending for asks
    };
}

// Delta 처리
function processDelta(orderBook: OrderBook, delta: any): OrderBook {
    const { b, a } = delta.data;

    const updatedBids = applyDelta(orderBook.bids, b, false);
    const updatedAsks = applyDelta(orderBook.asks, a, true);

    return {
        bids: updatedBids,
        asks: updatedAsks,
    };
}

// Delta 적용 함수
function applyDelta(orders: Order[], changes: [string, string][], isAsk: boolean): Order[] {
    let updatedOrders = [...orders];

    changes.forEach(([priceStr, sizeStr]) => {
        const price = parseFloat(priceStr);
        const size = parseFloat(sizeStr);

        const index = updatedOrders.findIndex(order => order[0] === price);

        if (size === 0) {
            // Remove order if size is 0
            if (index !== -1) {
                updatedOrders.splice(index, 1);
            }
        } else {
            if (index !== -1) {
                // Update size if order exists
                updatedOrders[index][1] = size;
            } else {
                // Add new order
                updatedOrders.push([price, size]);
            }
        }
    });

    // Sort updated orders
    return updatedOrders.sort((a, b) => (isAsk ? a[0] - b[0] : b[0] - a[0]));
}

const tvWsClientObj = {};

const onSubscribeBars = async (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
    console.log("[Tv Chart] On Subscribe Bars", symbolInfo, subscribeUID)
    const exchangeName = symbolInfo.exchange;
    const ticker = symbolInfo.name;
    const exchangeApi = new ExchangeApi(exchangeName);
    const symbolName = await exchangeApi.getWsSymbol(ticker) ?? await exchangeApi.getExchangeSymbol(ticker)
    const tempWsClient = new CustomStreaming(exchangeName);
    await tempWsClient.startWsClient();
    tvWsClientObj[subscribeUID] = tempWsClient;
    const tradeSubscribeMsg = exchangeApi.config.getTradeSubscribeMsg(symbolName)
    tempWsClient.sendMsg(tradeSubscribeMsg)
    tempWsClient.setTvHandler({ symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback })
}

const onUnSubscribeBars = (subscribeUID) => {
    console.log("[Tv Chart] On UnSubscribe Bars", subscribeUID)
    const tvWsClient = tvWsClientObj[subscribeUID];
    const tvHandler = tvWsClient.getTvHandler();
    const exchangeName = tvHandler.symbolInfo.exchange
    const symbolName = tvHandler.symbolInfo.name;
    const tradeUnSubscribeMsg = exchangeConfig[exchangeName].getTradeUnsubscribeMsg(symbolName)
    tvWsClient.sendMsg(tradeUnSubscribeMsg)
    tvWsClient.closeWsClient();
    delete tvWsClientObj[subscribeUID]
}

const EXCHANGE = "Bybit";
const SYMBOL = "BTCUSDT";
const TICKER = "BTC/USDT";

const TradingChart = () => {
    const [depth, setDepth] = useState({ bids: [], asks: [] });
    const [tvWidget, setTvWidget] = useState({} as IChartingLibraryWidget);

    useEffect(() => {
        const wsClient = new ExchangeWsClient(onMsgReceived);
        wsClient.startWsClient(EXCHANGE, SYMBOL, 'depth')
        return () => {
            wsClient.closeWsClient()
        }
    }, [])

    const onMsgReceived = useCallback((obj) => {
        // console.log("[TradingChart] onMsgReceived", obj);
        if (obj.type === "snapshot") {
            const newDepth = processSnapshot(obj);
            setDepth(newDepth);
        } else if (obj.type === "delta") {
            setDepth(prevDepth => processDelta(prevDepth, obj));
        }
    }, []);

    const onChartReady = (tvHandler: IChartingLibraryWidget) => {
        console.log("Chart Ready", tvHandler);
        setTvWidget(tvHandler);
    }


    return (
        <Card>
            <div className="p-2 rounded-md flex flex-col">
                <PageTitle>Trading Chart</PageTitle>
                <Card>
                    <TvChart
                        exchange={EXCHANGE}
                        ticker={TICKER}
                        dataFeed={{ ...CustomDatafeed, subscribeBars: onSubscribeBars, unsubscribeBars: onUnSubscribeBars }}
                        onChartReady={onChartReady}
                    />
                </Card>
                <Card>
                    <div className="p-2">
                        <Depth
                            askArr={depth.asks}
                            bidArr={depth.bids}
                            limit={10}
                        />
                    </div>
                </Card>
            </div>
        </Card>
    );
}

export default TradingChart;
