import _ from "lodash";
import { IExchangeConfig } from "apis/exchange/interface";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1m',
    '3': '3m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1h',
    '120': '2h',
    '240': '4h',
    '360': '6h',
    '480': '8h',
    '720': '12h',
    'D': '1d',
    '1D': '1d',
    '3D': '3d',
    'W': '1w',
    '1W': '1w',
    'M': '1M',
    '1M': '1M'
}

const BinanceConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/binance",

    wsEndpoint: "wss://stream.binance.com:9443/ws",

    defaultTicker: "BTC/USDT",

    spotTickersRestPath: "/api/v3/exchangeInfo",

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Symbols] Binance`, rawSymbolsData)
        if (_.isNil(rawSymbolsData?.symbols)) {
            throw { msg: "Binance Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        rawSymbolsData?.symbols.forEach((elm) => { // krw market
            const { baseAsset, quoteAsset, symbol } = elm;
            const ticker = `${baseAsset}/${quoteAsset}`;
            const exchangeSymbol = symbol;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        console.log(`[Parse Symbols] Binance`, _.size(symbolInfoObj))
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] Binance`, symbol, resolution)
        return `/api/v3/klines`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] Binance`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {
            symbol,
            interval: resolutionMap[resolution],
        }
    },

    parseHistoricalBar: function (rawBarData: any) {
        // console.log(`[Parse Historical Bar] Binance`, rawBarData)
        let bars = [];
        if (_.isNil(rawBarData)) {
            throw { msg: "Binance Parse Bars Error" }
        }
        rawBarData.forEach((elm) => {
            bars = [...bars, {
                time: Number(elm[0]),
                open: Number(elm[1]),
                high: Number(elm[2]),
                low: Number(elm[3]),
                close: Number(elm[4]),
                // volume: Number(elm.v) * Number(elm.o)
                volume: Number(elm[5])
            }];
        })
        return bars;
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] Binance`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("SUBSCRIBE", symbol)
        return {
            "method": "SUBSCRIBE",
            "params":
                [
                    `${symbol.toLowerCase()}@trade`
                ],
            "id": 312
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("UNSUBSCRIBE", symbol)
        return {
            "method": "UNSUBSCRIBE",
            "params":
                [
                    `${symbol.toLowerCase()}@trade`
                ],
            "id": 312
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] Binance`, rawMsgObj)
        if (rawMsgObj.id === 312) {
            console.log("[Parse Trade Tick] Binance Subscribe Success", rawMsgObj)
            return { status: "other" };
        }
        if (rawMsgObj.e !== "trade") {
            console.log("[Parse Trade Tick] Binance Other Message", rawMsgObj)
            return { status: "other" };
        }
        const tradeTime = Number(rawMsgObj?.T);
        const tradePrice = Number(rawMsgObj?.p);
        const tradeSize = Number(rawMsgObj?.q);
        return { tradeTime, tradePrice, tradeSize, status: "diff" }
    }
}

export default BinanceConfig;