import _ from "lodash";
import { IExchangeConfig } from "apis/exchange/interface";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '60m',
    '240': '4h',
    '1D': '1d',
    '1W': '1d',
    '1M': '1M'
}

const MexcConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/mexc",

    wsEndpoint: "wss://wbs.mexc.com/ws",

    defaultTicker: "BTC/USDT",

    spotTickersRestPath: "/api/v3/exchangeInfo",

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Symbols] MEXC`, rawSymbolsData)
        if (_.isNil(rawSymbolsData?.symbols)) {
            throw { msg: "MEXC Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        rawSymbolsData?.symbols.forEach((elm) => { // krw market
            const { baseAsset, quoteAsset, symbol } = elm;
            const ticker = `${baseAsset}/${quoteAsset}`;
            const exchangeSymbol = symbol;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        console.log(`[Parse Symbols] MEXC`, _.size(symbolInfoObj))
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] MEXC`, symbol, resolution)
        return `/api/v3/klines`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] MEXC`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {
            symbol: symbol,
            interval: resolutionMap[resolution],
            startTime: requestFromMsec,
            endTime: requestToMsec,
            limit: limit,
        }
    },

    parseHistoricalBar: function (rawBarData: any) {
        // console.log(`[Parse Historical Bar] MEXC`, rawBarData)
        let bars = [];
        if (_.isNil(rawBarData)) {
            throw { msg: "MEXC Parse Bars Error" }
        }
        rawBarData.forEach(elm => {
            bars = [...bars, {
                time: Number(elm[0]),
                open: Number(elm[1]),
                high: Number(elm[2]),
                low: Number(elm[3]),
                close: Number(elm[4]),
                volume: Number(elm[7])
            }];
        })
        return bars;
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] MEXC`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("SUBSCRIBE", symbol)
        return {
            method: "SUBSCRIPTION",
            params: [
                `spot@public.deals.v3.api@${symbol}`
            ]
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("UNSUBSCRIBE", symbol)
        return {
            method: "UNSUBSCRIPTION",
            params: [
                `spot@public.deals.v3.api@${symbol}`
            ]
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] MEXC`, rawMsgObj)
        if (!rawMsgObj?.c?.match(new RegExp(`deals`))) {
            return { status: "otherMsg" }
        }
        const tradeTime = Number(rawMsgObj?.d?.deals?.[0].t);
        const tradePrice = Number(rawMsgObj?.d?.deals?.[0].p);
        const tradeSize = Number(rawMsgObj?.d?.deals?.[0].v);
        return { tradeTime, tradePrice, tradeSize, status: "diff" }
    }
}

export default MexcConfig;
