import _ from "lodash";
import { IExchangeConfig } from "apis/exchange/interface";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1m',
    '3': '3m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1H',
    '120': '2H',
    '240': '4H',
    '360': '6Hutc',
    '720': '12Hutc',
    'D': '1Dutc',
    '1D': '1Dutc',
    '3D': '3Dutc',
    'W': '1Wutc',
    '1W': '1Wutc',
    'M': '1Mutc',
    '1M': '1Mutc'
}

const OkxConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/okx",

    wsEndpoint: "wss://ws.okx.com:8443/ws/v5/public",

    defaultTicker: "BTC/USDT",

    spotTickersRestPath: "/api/v5/public/instruments?instType=SPOT",

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Symbols] Okx`, rawSymbolsData)
        const symbolRet = rawSymbolsData?.data;
        const code = rawSymbolsData?.code;
        if (_.isNil(symbolRet)) {
            throw { msg: "Okx Parse Symbols Error" }
        }
        if (code !== "0") {
            throw { msg: "Okx Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        symbolRet.forEach((elm) => { // krw market
            const { baseCcy, quoteCcy, instId } = elm;
            const ticker = `${baseCcy}/${quoteCcy}`;
            const exchangeSymbol = instId;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        console.log(`[Parse Symbols] Okx`, _.size(symbolInfoObj))
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] Okx`, symbol, resolution)
        return `/api/v5/market/candles`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 300,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] Okx`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {
            instId: symbol,
            after: requestToMsec,
            before: requestFromMsec,
            bar: resolutionMap[resolution],
            limit: limit,
        }
    },

    parseHistoricalBar: function (rawBarData: any) {
        console.log(`[Parse Historical Bar] Okx`, rawBarData)
        let bars = [];
        const barRet = rawBarData?.data;
        const code = rawBarData?.code;
        if (_.isNil(barRet)) {
            throw { msg: "Okx Parse Bars Error" }
        }
        if (code !== "0") {
            throw { msg: "Okx Parse Bars Error" }
        }
        barRet.forEach((elm) => {
            bars = [...bars, {
                time: Number(elm[0]),
                open: Number(elm[1]),
                high: Number(elm[2]),
                low: Number(elm[3]),
                close: Number(elm[4]),
                // volume: Number(elm.v) * Number(elm.o)
                volume: Number(elm[5])
            }];
        })
        return bars.sort((a, b) => a.time - b.time);
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] Okx`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("[Subscribe Message] Okx", symbol)
        return {
            op: "subscribe",
            args: [
                {
                    "channel": "trades",
                    "instId": symbol
                }
            ]
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("[Unsubscribe Messsage] Okx", symbol)
        return {
            op: "unsubscribe",
            args: [
                {
                    "channel": "trades",
                    "instId": symbol
                }
            ]
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] Okx`, rawMsgObj)
        const tradeRet = rawMsgObj?.data;
        const channel = rawMsgObj?.arg?.channel;
        if (_.isNil(tradeRet)) {
            console.log("[Parse Trade Tick] Okx Other Message 1", rawMsgObj)
            return { status: "other" };
        }
        if (channel !== "trades") {
            console.log("[Parse Trade Tick] Okx Other Message 2", rawMsgObj)
            return { status: "other" };
        }
        let tradeArr = [];
        tradeRet.forEach((elm) => {
            const tradeTime = Number(elm.ts); //msec
            const tradePrice = Number(elm.px);
            const tradeSize = Number(elm.sz);
            const newTrade = {
                tradeTime,
                tradePrice,
                tradeSize,
            }
            tradeArr.push(newTrade)
        })
        // console.log(`[Parsed Trade Tick] Okx`, tradeArr)
        return { tradeArr, status: "array" };
    }
}

export default OkxConfig;