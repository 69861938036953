import _ from "lodash";
import { IExchangeConfig } from "apis/exchange/interface";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1',
    '5': '5',
    '15': '15',
    '30': '30',
    '60': '60',
    '240': '240',
    'D': '1440',
    '1D': '1440',
    'W': '10080',
    '1W': '10080',
    'M': '21600',
    '1M': '21600'
}

const KrakenConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/kraken",

    wsEndpoint: "wss://ws.kraken.com",

    defaultTicker: "XXBT/ZUSD",

    spotTickersRestPath: "/0/public/AssetPairs",

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Symbols] Kraken`, rawSymbolsData)
        const symbolRet = rawSymbolsData?.result;
        const error = rawSymbolsData?.error;
        if (_.isNil(symbolRet)) {
            throw { msg: "Kraken Parse Symbols Error" }
        }
        if (!_.isEmpty(error)) {
            throw { msg: "Kraken Parse Symbols Error", error }
        }
        let symbolInfoObj = {};
        Object.keys(symbolRet).forEach((symbol) => { // krw market
            const elm = symbolRet[symbol];
            const { base, quote, wsname } = elm;
            const ticker = `${base}/${quote}`;
            const exchangeSymbol = symbol;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol, wsSymbol: wsname }
        })
        console.log(`[Parse Symbols] Kraken`, _.size(symbolInfoObj))
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] Kraken`, symbol, resolution)
        return `/0/public/OHLC`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] Kraken`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {
            pair: symbol,
            interval: resolutionMap[resolution],
            since: requestFromMsec / 1000,
        }
    },

    parseHistoricalBar: function (rawBarData: any) {
        // console.log(`[Parse Historical Bar] Kraken`, rawBarData)
        let bars = [];
        const barRet = _.toArray(rawBarData?.result)[0];
        const error = rawBarData?.error;
        if (_.isNil(barRet)) {
            throw { msg: "Kraken Parse Bars Error" }
        }
        if (!_.isEmpty(error)) {
            throw { msg: "Kraken Parse Bars Error" }
        }
        barRet.forEach((elm) => {
            bars = [...bars, {
                time: Number(elm[0]) * 1000,
                open: Number(elm[1]),
                high: Number(elm[2]),
                low: Number(elm[3]),
                close: Number(elm[4]),
                volume: Number(elm[6]) //5 is vwap
            }];
        })
        return bars.sort((a, b) => a.time - b.time);
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] Kraken`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("[Subscribe Message] Kraken", symbol)
        return {
            event: "subscribe",
            pair: [
                symbol
            ],
            subscription: {
                name: "trade"
            }
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("[Unsubscribe Messsage] Kraken", symbol)
        return {
            event: "unsubscribe",
            pair: [
                symbol
            ],
            subscription: {
                name: "trade"
            }
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] Kraken`, rawMsgObj)
        const tradeRet = rawMsgObj;
        const event = rawMsgObj?.event;
        if (!_.isNil(event)) {
            // console.log("[Parse Trade Tick] Kraken Other Message 1", rawMsgObj)
            return { status: "other" };
        }
        if (!_.isArray(tradeRet)) {
            // console.log("[Parse Trade Tick] Kraken Other Message 2", rawMsgObj)
            return { status: "other" };
        }
        if (tradeRet.length < 4) {
            // console.log("[Parse Trade Tick] Kraken Other Message 3", rawMsgObj)
            return { status: "other" };
        }
        const [channelID, rawTrades, channel, symbol] = tradeRet;
        let tradeArr = [];
        rawTrades?.forEach((elm) => {
            const tradeTime = Number(elm[2]) * 1000;
            const tradePrice = Number(elm[0]);
            const tradeSize = Number(elm[1]);
            const newTrade = {
                tradeTime,
                tradePrice,
                tradeSize,
            }
            tradeArr.push(newTrade)
        })
        // console.log(`[Parsed Trade Tick] Kraken`, tradeArr)
        return { tradeArr, status: "array" };
    }
}

export default KrakenConfig;