import _ from "lodash";
import { IExchangeConfig } from "apis/exchange/interface";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1h',
    '240': '4h',
    '480': '8h',
    '1D': '1d',
    '1W': '7d',
    '1M': '30d'
}


const GateioConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/gateio",

    wsEndpoint: "wss://api.gateio.ws/ws/v4/",

    defaultTicker: "BTC/USDT",

    spotTickersRestPath: "/api/v4/spot/currency_pairs",

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Symbols] Gate.io`, rawSymbolsData)
        if (_.isNil(rawSymbolsData)) {
            throw { msg: "Gate.io Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        rawSymbolsData?.forEach((elm) => { // krw market
            const { base, quote, id } = elm;
            const ticker = `${base}/${quote}`;
            const exchangeSymbol = id;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        console.log(`[Parse Symbols] Gate.io`, _.size(symbolInfoObj))
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] Gate.io`, symbol, resolution)
        return `/api/v4/spot/candlesticks`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] Gate.io`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {
            currency_pair: symbol,
            to: requestToMsec / 1000,
            limit: limit,
            interval: resolutionMap[resolution],
        }
    },

    parseHistoricalBar: function (rawBarData: any) {
        // console.log(`[Parse Historical Bar] Gate.io`, rawBarData)
        let bars = [];
        if (_.isNil(rawBarData)) {
            throw { msg: "Gate.io Parse Bars Error" }
        }
        rawBarData.forEach(elm => {
            bars = [...bars, {
                time: Number(elm[0] * 1000),
                open: Number(elm[5]),
                high: Number(elm[3]),
                low: Number(elm[4]),
                close: Number(elm[2]),
                volume: Number(elm[1])
            }];
        })
        return bars;
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] Gate.io`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("SUBSCRIBE", symbol)
        return {
            "time": new Date().getTime(),
            "channel": "spot.trades",
            "event": "subscribe",
            "payload": [symbol]
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("UNSUBSCRIBE", symbol)
        return {
            "time": new Date().getTime(),
            "channel": "spot.trades",
            "event": "unsubscribe",
            "payload": [symbol]
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] Gate.io`, rawMsgObj)
        const msgEvent = rawMsgObj.event;
        if (msgEvent !== "update") {
            return { status: "otherMsg" }
        }
        const tradeTime = Number(rawMsgObj.result.create_time_ms);
        const tradePrice = Number(rawMsgObj.result.price);
        const tradeSize = Number(rawMsgObj.result.amount);
        return { tradeTime, tradePrice, tradeSize, status: "diff" }
    }
}

export default GateioConfig;