import axios from "axios";
import _ from "lodash";
import { IExchangeConfig, ITickers } from "apis/exchange/interface";
import exchangeConfig from "apis/exchange/config";

const TV_PROXY_URL = "https://rest.defimix.io/tv";

class ExchangeApi {
    exchangeName: string;
    config: IExchangeConfig;
    tickers: ITickers = {};
    constructor(exchangeName: string) {
        console.log("[ExchangeApi]", exchangeName, "Costructor Called");
        this.exchangeName = exchangeName;
        this.config = exchangeConfig[exchangeName]
        this.init();
    }

    init = async () => {
        await this.updateExchangeInfo();
    }

    updateExchangeInfo = async () => {
        if (!_.isEmpty(this.tickers)) {
            console.log("[Update Exchange Info] Already Updated")
            return;
        }
        const rawTickers = await axios.get(`${TV_PROXY_URL}${this.config.restProxyBasePath}/${this.config.spotTickersRestPath}`);
        const tickers = this.config.parseTickers(rawTickers.data);
        this.tickers = tickers;
    }

    getTickers = () => {
        return this.tickers;
    }

    getDefaultTicker = () => {
        return this.config.defaultTicker;
    }

    getExchangeSymbol = async (ticker: string) => {
        console.log("[Get Exchange Symbol]", ticker, this.tickers[ticker]);
        if(_.isNil(this.tickers[ticker])) {
            await this.updateExchangeInfo();
        }
        return this.tickers[ticker].exchangeSymbol;
    }

    getWsSymbol = async (ticker: string) => {
        if(_.isNil(this.tickers[ticker])) {
            await this.updateExchangeInfo();
        }
        console.log("[Get Ws Symbol]", ticker, this.tickers[ticker])
        return this.tickers[ticker].wsSymbol;
    }

    getBars = async (ticker: string, requestFromMsec: number, requestToMsec: number, barLimit: number, resolution: string) => {
        try {
            const exchangeSymbol = await this.getExchangeSymbol(ticker);
            const params = this.config.getCandleRequestParams(exchangeSymbol, requestFromMsec, requestToMsec, barLimit, resolution);
            const candlePath = this.config.getCandleRestPath(exchangeSymbol, resolution)
            const rawBarRet = await axios.get(`${TV_PROXY_URL}${this.config.restProxyBasePath}${candlePath}`, { params });
            const bars = this.config.parseHistoricalBar(rawBarRet.data);
            return bars;
        } catch (e) {
            console.log("[Get Bar Error]", e)
            return [];
        }
    }
}

export default ExchangeApi;