import _ from "lodash";
import { IExchangeConfig } from "apis/exchange/interface";
import axios from "axios";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const TV_PROXY_URL = "https://rest.defimix.io/tv";

const resolutionMap = {
    '1': '1min',
    '3': '3min',
    '5': '5min',
    '15': '15min',
    '30': '30min',
    '60': '1hour',
    '120': '2hour',
    '240': '4hour',
    '360': '6hour',
    '480': '8hour',
    '720': '12hour',
    '1D': '1day',
    'D': '1day',
}
const restProxyBasePath = "/chart/kucoin";

const KuCoinConfig: IExchangeConfig = {
    restProxyBasePath,

    wsEndpoint: "",

    getWsEndpoint: async () => {
        const ret = await axios.post(`${TV_PROXY_URL}${restProxyBasePath}/api/v1/bullet-public`);
        const baseWsUrl = ret.data?.data?.instanceServers?.[0]?.endpoint;
        const token = ret.data?.data?.token;
        const wsUrl = `${baseWsUrl}?token=${token}`;
        return wsUrl;
    },

    defaultTicker: "BTC/USDT",

    spotTickersRestPath: "/api/v2/symbols",

    parseTickers: function (rawSymbolsData: any) {
        // console.log(`[Parse Symbols] KuCoin`, rawSymbolsData)
        const symbolRet = rawSymbolsData?.data;
        if (_.isNil(symbolRet)) {
            throw { msg: "KuCoin Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        symbolRet.forEach((elm) => { // krw market
            const { baseCurrency, quoteCurrency, symbol } = elm;
            const ticker = `${baseCurrency}/${quoteCurrency}`;
            const exchangeSymbol = symbol;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        console.log(`[Parse Symbols] KuCoin`, _.size(symbolInfoObj))
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        // console.log(`[Get Candle Rest Path] KuCoin`, symbol, resolution)
        return `/api/v1/market/candles`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] KuCoin`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {
            symbol: symbol,
            type: resolutionMap[resolution],
            startAt: requestFromMsec / 1000,
            endAt: requestToMsec / 1000,
        }
    },

    parseHistoricalBar: function (rawBarData: any) {
        // console.log(`[Parse Historical Bar] KuCoin`, rawBarData)
        const barRet = rawBarData?.data;
        let bars = [];
        if (_.isNil(barRet)) {
            throw { msg: "KuCoin Parse Bars Error" }
        }
        barRet.forEach(elm => {
            bars = [...bars, {
                time: Number(elm[0]) * 1000,
                open: Number(elm[1]),
                high: Number(elm[3]),
                low: Number(elm[4]),
                close: Number(elm[2]),
                volume: Number(elm[5])
            }];
        })
        return bars.sort((a, b) => a.time - b.time);
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] KuCoin`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("SUBSCRIBE", symbol)
        return {
            id: 10101,
            type: "subscribe",
            topic: `/market/match:${symbol}`,
            privateChannel: false,
            response: true
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("UNSUBSCRIBE", symbol)
        return {
            id: 10101,
            type: "unsubscribe",
            topic: `/market/match:${symbol}`,
            privateChannel: false,
            response: true
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] KuCoin`, rawMsgObj)
        const tradeRet = rawMsgObj?.data;
        if (_.isNil(tradeRet)) {
            console.log("KuCoin Parse Other Message 1")
            return { status: "other" };
        }
        const subject = rawMsgObj.subject;
        if(subject !== "trade.l3match") {
            console.log("KuCoin Parse Other Message 2")
            return { status: "other" };
        }
        const tradeTime = Number(tradeRet.time / 1e6);
        const tradePrice = Number(tradeRet.price);
        const tradeSize = Number(tradeRet.size);
        return { tradeTime, tradePrice, tradeSize, status: "diff" }
    }
}

export default KuCoinConfig;