
import React from "react";
import { Routes, Route } from "react-router-dom";

import TradingChart from "pages/Trading/Chart";

export default () => (
    <Routes>
        <Route path="/chart" element={<TradingChart />}></Route>
    </Routes>
)

