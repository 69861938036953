import { IExchangeConfig } from "apis/exchange/interface";
// 1m, 3m, 5m, 10m, 30m, 1h, 6h, 12h, 24h

const resolutionMap = {
    '1': '1M',
    '3': '3M',
    '5': '5M',
    '10': '10M',
    '30': '30M',
    '60': '1h',
    '360': '6h',
    '1D': '24h',
    '1W': '1w',
    '1M': '1m'
}

const BithumbConfig: IExchangeConfig = {
    restProxyBasePath: "/chart/bithumb",

    wsEndpoint: "wss://wss1.bithumb.com/public",

    defaultTicker: "BTC/KRW",

    spotTickersRestPath: "/exchange/v1/comn/intro",

    parseTickers: function (rawSymbolsData: any) {
        console.log(`[Parse Symbols] Bithumb`, rawSymbolsData)
        if (rawSymbolsData?.status !== 200) {
            throw { msg: "Bithumb Parse Symbols Error" }
        }
        let symbolInfoObj = {};
        rawSymbolsData?.data?.coinsOnMarketList["C0100"].forEach((elm) => { // krw market
            const { coinSymbol, coinType } = elm;
            const ticker = `${coinSymbol}/KRW`;
            const exchangeSymbol = `${coinType}_C0100`;
            const precision = 1;
            symbolInfoObj[ticker] = { precision, exchangeSymbol }
        })
        return symbolInfoObj;
    },

    getCandleRestPath: function (symbol: string, resolution: string): string {
        console.log(`[Get Candle Rest Path] Bithumb`, symbol, resolution)
        return `/observer/chart/public/candlesticknew_trview/${symbol}/${resolutionMap[resolution]}`;
    },

    intradayMultipliers: Object.keys(resolutionMap).filter((key) => key !== '1D' && key !== '1W' && key !== '1M'),
    resolutionMap,
    hasWeekly: false,
    hasMonthly: false,
    barLimit: 1500,

    getCandleRequestParams: function (symbol: string, requestFromMsec: number, requestToMsec: number, limit: number, resolution: string) {
        console.log(`[Get Candle Request Params] Bithumb`, symbol, requestFromMsec, requestToMsec, limit, resolution)
        return {}
    },

    parseHistoricalBar: function (rawBarData: any) {
        // console.log(`[Parse Historical Bar] Bithumb`, rawBarData)
        let bars = [];
        if (rawBarData?.status !== "0000") {
            throw { msg: "Bithumb Parse Bars Error" }
        }
        const tArr = rawBarData?.data?.t;
        const oArr = rawBarData?.data?.o;
        const hArr = rawBarData?.data?.h;
        const lArr = rawBarData?.data?.l;
        const cArr = rawBarData?.data?.c;
        const vArr = rawBarData?.data?.v;
        tArr.forEach((t, i) => {
            bars = [...bars, {
                time: Number(t),
                open: Number(oArr[i]),
                high: Number(hArr[i]),
                low: Number(lArr[i]),
                close: Number(cArr[i]),
                // volume: Number(elm.v) * Number(elm.o)
                volume: Number(vArr[i])
            }];
        })
        return bars;
    },

    getSpotDepthRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotDepthReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullDepth: function (rawDepthData: any) {
        throw new Error("Function not implemented.");
    },

    getDepthSubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    getDepthUnsubscribeMsg: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseDepthTick: function (rawMsgObj: any) {
        throw new Error("Function not implemented.");
    },

    getSpotTradeRestPath: function (symbol: string): string {
        throw new Error("Function not implemented.");
    },

    getSpotTradeReqParams: function (symbol: string) {
        throw new Error("Function not implemented.");
    },

    parseFullTrade: function (rawTradeData: any) {
        console.log(`[Parse Full Trade] Bithumb`, rawTradeData)
        throw new Error("Function not implemented.");
    },

    getTradeSubscribeMsg: function (symbol: string) {
        console.log("SUBSCRIBE", symbol) // Example: C0101_C0100
        return {
            "type": "SUBSCRIBE",
            "events": [
                {
                    "type": "tr",
                    "filters": [
                        ...symbol.split("_")
                    ]
                }
            ]
        }
    },

    getTradeUnsubscribeMsg: function (symbol: string) {
        console.log("UNSUBSCRIBE", symbol) // Example: C0101_C0100
        return {
            "type": "UNSUBSCRIBE",
            "events": [
                {
                    "type": "tr",
                    "filters": [
                        ...symbol.split("_")
                    ]
                }
            ]
        }
    },

    parseTradeTick: function (rawMsgObj: any) {
        // console.log(`[Parse Trade Tick] Bithumb`, rawMsgObj)
        if (rawMsgObj.status === "SUCCESS") {
            console.log("[Parse Trade Tick] Bithumb Subscribe Success", rawMsgObj)
            return { status: "other" };
        }
        if (rawMsgObj.subtype !== "tr") {
            throw { msg: "Bithumb Parse Trade Error" }
        }
        let tradeArr = [];
        rawMsgObj?.content?.l?.forEach((elm) => {
            const tradeTime = new Date(elm.t).getTime();
            const tradePrice = Number(elm.p);
            const tradeSize = Number(elm.q);
            const newTrade = {
                tradeTime,
                tradePrice,
                tradeSize,
            }
            tradeArr.push(newTrade)
        })
        // console.log(`[Parsed Trade Tick] Bithumb`, tradeArr)
        return { tradeArr, status: "array" };
    }
}

export default BithumbConfig;