import React from 'react';
import { classNames } from 'components/uiUtils';
const Card = ({ paddingClassNames = "py-2 px-2 lg:px-2", children }) => {
    return (
        <div className={classNames(
            "flex align-middle",
            paddingClassNames
        )}>
            <div className="shadow ring-1 ring-black ring-opacity-5 w-full">
                {children}
            </div>
        </div>

    )
}

export default Card;