import _ from 'lodash';
import CustomDatafeed from './datafeed';
import exchangeConfig from 'apis/exchange/config'
import { resolutionToMsec } from './datafeed'
import ExchangeApi from 'apis/exchange';

const getNextBarTime = (barTime, resolution) => {
    const date = new Date(barTime * 1000);
    date.setTime(date.getTime() + resolutionToMsec(resolution) * 1000);
    return date.getTime() / 1000;
}

export default class TvWsClient {
    tvHandler: any
    exchangeName: string;
    exchangeApi: ExchangeApi;
    wsClient: WebSocket;
    constructor(exchangeName: string) {
        this.tvHandler = {};
        this.exchangeName = exchangeName;
        this.exchangeApi = new ExchangeApi(exchangeName);
    }

    startWsClient = async () => {
        return new Promise(async (resolve: any) => {
            let wsEndpoint = exchangeConfig[this.exchangeName].wsEndpoint;
            if(!_.isNil(exchangeConfig[this.exchangeName].getWsEndpoint)){
                wsEndpoint = await exchangeConfig[this.exchangeName].getWsEndpoint();
            }
            const wsClient = new WebSocket(wsEndpoint);
            wsClient.binaryType = "blob"; // for upbit

            wsClient.onopen = () => {
                const connectedMsg = `[Tv Public Ws] Connected`;
                console.log(connectedMsg);
                resolve();
            }

            wsClient.onclose = () => {
                const closeMsg = `[Tv Public Ws] Closed`;
                console.log(closeMsg);
                // onClosed();
            }

            wsClient.onmessage = async (event) => {
                if (event.data instanceof Blob) {
                    const txt = await event.data.text();
                    const msgObj = JSON.parse(txt);
                    this.processMsg(msgObj);
                    return;
                }
                const msgObj = JSON.parse(event.data);
                this.processMsg(msgObj);
            }

            this.wsClient = wsClient
        })
    }

    processMsg = (msgObj) => {
        // console.log("[TvWsClient] Process Msg", msgObj)
        const exchangeName = this.tvHandler.symbolInfo.exchange;
        const fullName = this.tvHandler.symbolInfo.full_name;
        const cacheKey = `${fullName}:${this.tvHandler.resolution}`
        // console.log("Last Bar From Cache", cacheKey)
        const ret = exchangeConfig[exchangeName].parseTradeTick(msgObj);
        const status = ret.status;
        if (status === "array") {
            const tradeArr = ret.tradeArr;
            tradeArr.forEach((tick) => {
                const { tradeTime, tradePrice, tradeSize } = tick;
                this.resolveTrade(cacheKey, tradeTime, tradePrice, tradeSize)
            })
        } else if (status === "diff") {
            const { tradeTime, tradePrice, tradeSize } = ret;
            this.resolveTrade(cacheKey, tradeTime, tradePrice, tradeSize)
        }
    }

    resolveTrade = (cacheKey, tradeTime, tradePrice, tradeSize) => {
        const lastBar = CustomDatafeed.lastBarsCache.get(cacheKey)
        const nextBarTime = getNextBarTime(lastBar.time, this.tvHandler.resolution);
        let bar;
        // console.log(toPrettyDtString(lastBar.time), toPrettyDtString(tradeTime), toPrettyDtString(nextBarTime))
        if (tradeTime >= nextBarTime) {
            // console.log("Case 1")
            bar = {
                time: nextBarTime,
                open: tradePrice,
                high: tradePrice,
                low: tradePrice,
                close: tradePrice,
                // volume: tradeSize * tradePrice
                volume: tradeSize
            };
            // console.log('[socket] Generate new bar', bar);
            // tvHandler.onResetCacheNeededCallback();
        } else {
            // console.log("Case 2")
            bar = {
                ...lastBar,
                // time: tradeTime,
                high: Math.max(lastBar.high, tradePrice),
                low: Math.min(lastBar.low, tradePrice),
                close: tradePrice,
                // volume: lastBar.volume + tradeSize * tradePrice
                volume: lastBar.volume + tradeSize
            };
            // console.log('[socket] Update the latest bar by price', tradePrice);
        }
        // console.log("Trade", tradeTime, parseInt(tradeSize * tradePrice), bar)
        // console.log("Insert New Bar", toPrettyDtString(bar.time))
        CustomDatafeed.lastBarsCache.set(cacheKey, bar)
        this.tvHandler.onRealtimeCallback({ ...bar })
    }

    sendMsg = (msgObj) => {
        this.wsClient.send(JSON.stringify(msgObj))
    }

    setTvHandler = (handler) => {
        this.tvHandler = handler
    }

    getTvHandler = () => this.tvHandler

    closeWsClient = () => {
        this.wsClient.close()
    }
}
