import CryptoJS from 'crypto-js';
import moment from 'moment';

export const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

export const scrollToElm = (elmId: string) => {
    setTimeout(() => {
        const elm = document.getElementById(elmId);
        if (elm) {
            elm.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    }); // zero if the argument was omitted
}

export const getSha10length = (str: string) => {
    const encrypted = CryptoJS.SHA256(str).toString(CryptoJS.enc.Base64);
    return encrypted.slice(0, 10);
}

export const tryParseDt = (dtStr: string) => {
    console.log("[tryParseDt] dtStr", dtStr);
    try {
        const dt = moment(dtStr).toDate();
        if (dt.toString() === "Invalid Date") {
            throw new Error("Invalid Date");
        }
        console.log("[tryParseDt] dt", dt);
        return dt;
    } catch (e) {
        return new Date();
    }
}

export const selectImgs = async (isMultiple = true) => {
    return new Promise((resolve, reject) => {
        const input = document.createElement('input');
        // multiple files
        if (isMultiple) {
            input.setAttribute('multiple', 'multiple');
        }
        input.setAttribute('accept', 'image/*');
        input.setAttribute('type', 'file');
        input.click();
        input.onchange = () => {
            const files = input.files;
            if (files.length > 0) {
                resolve(files);
            } else {
                reject('No files selected');
            }
        };
        input.onerror = () => {
            reject('Error selecting files');
        };
    });
}

export const randomStr = (length: number) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}