export const EXAMPLE_BIDS = [
    [0.0001, 100],
    [0.0002, 200],
    [0.0003, 300],
    [0.0004, 400],
    [0.0005, 500],
    [0.0006, 600],
    [0.0007, 700],
    [0.0008, 800],
    [0.0009, 900],
    [0.001, 1000],
]

export const EXAMPLE_ASKS = [
    [0.0011, 1000],
    [0.0012, 900],
    [0.0013, 800],
    [0.0014, 700],
    [0.0015, 600],
    [0.0016, 500],
    [0.0017, 400],
    [0.0018, 300],
    [0.0019, 200],
    [0.002, 100],
]
